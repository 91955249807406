import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { userSignOut } from "appRedux/actions/Auth";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import IntlMessages from "util/IntlMessages";
import { NAV_STYLE_MINI, THEME_TYPE_LITE } from "constants/ThemeSetting";
import Auxiliary from "util/Auxiliary";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  render() {
    const { navStyle, themeType, verticalNavStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            inlineCollapsed={verticalNavStyle === NAV_STYLE_MINI}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup
              key="main"
              title={<IntlMessages id="sidebar.main" />}
            >
              <Menu.Item key="home">
                <Link to="/home">
                  <i class="fas icon fa-home"></i>
                  <IntlMessages id="Home" />
                </Link>
              </Menu.Item>

              <Menu.Item key="food_trucks">
                <Link to="/food_trucks">
                  <i class="icon fas fa-golf-ball"></i>
                  <IntlMessages id="Food Trucks" />
                </Link>
              </Menu.Item>

              <Menu.Item key="orders">
                <Link to="/orders">
                  <i class="icon fas fa-file-invoice"></i>
                  <IntlMessages id="Orders" />
                </Link>
              </Menu.Item>
            </MenuItemGroup>
            <MenuItemGroup
              key="main"
              title={<IntlMessages id="Menu Management" />}
            >
              <Menu.Item key="category">
                <Link to="/category">
                  <i class="fas icon fa-bars"></i>
                  <IntlMessages id="Category" />
                </Link>
              </Menu.Item>

              <Menu.Item key="sub-category">
                <Link to="/sub-category">
                  <i class="far icon fa-list-alt"></i>
                  <IntlMessages id="Sub Category" />
                </Link>
              </Menu.Item>

              <Menu.Item key="menu-item">
                <Link to="/menus">
                  <i className="icon icon-widgets" />
                  <IntlMessages id="Menu Item" />
                </Link>
              </Menu.Item>

              <Menu.Item key="extra-item">
                <Link to="/extra-items">
                  <i class="fas fa-hamburger icon"></i>
                  <IntlMessages id="Extra Item" />
                </Link>
              </Menu.Item>

              <Menu.Item key="extra-sub-item">
                <Link to="/extra-sub-items">
                  <i class="fas icon fa-cookie-bite"></i>
                  <IntlMessages id="Extra Sub Item" />
                </Link>
              </Menu.Item>

              {/*<Menu.Item key="sample">
                <Link to="/sample">
                  <i className="icon icon-widgets" />
                  <IntlMessages id="sidebar.samplePage" />
                </Link>
                </Menu.Item>*/}
            </MenuItemGroup>

            <MenuItemGroup key="main" title={<IntlMessages id="Promo code" />}>
              <Menu.Item key="promo-code">
                <Link to="/promo-code">
                  <i class="fas icon fa-tags"></i>
                  <IntlMessages id="Promo code" />
                </Link>
              </Menu.Item>
            </MenuItemGroup>
            <MenuItemGroup key="main" title={<IntlMessages id="Profile" />}>
              <Menu.Item key="logout">
                <a onClick={() => this.props.userSignOut()}>
                  <i class="fas icon fa-sign-out-alt"></i>
                  <IntlMessages id="Logout" />
                </a>
              </Menu.Item>
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { navStyle, verticalNavStyle, themeType, locale, pathname } = settings;
  return { navStyle, verticalNavStyle, themeType, locale, pathname };
};
export default connect(mapStateToProps, { userSignOut })(SidebarContent);
